import { useQuery } from '@tanstack/react-query'
import { useParamQuery } from 'hooks/useParamQuery'
import { showNotification } from '@mantine/notifications'
import { getProductRecommendationSelecteds } from 'api/routine'

interface Translation {
	id: number
	field_name: string
	language: string
	value: string
	attribute_name?: string
	collection_name?: string
}

export interface IRoutine {
	batch_id: number
	category: string
	category_translations: Translation[]
	collection: string
	collection_translations: Translation[]
	customer_id: number
	description: string
	id: number
	image_url: string
	link: string
	name: string
	product_type: string
	product_translations: Translation[]
	routine: string
}

export const useRoutine = () => {
	const { params } = useParamQuery()
	const active = params.get('active')
	const batchId = params.get('batch_id')
	const customerId = params.get('customer_id')
	const market = params.get('market')

	const { data = { makeup: [], skincare: [] }, isLoading } = useQuery(
		['product-recommendation-selecteds', batchId, customerId, active],
		async () =>
			await getProductRecommendationSelecteds({
				batch_id: batchId,
			}),
		{
			select: data => {
				const products = data.data.data.map((product: IRoutine) => {
					const { id, name, collection, routine, link, image_url: imageUrl } = product

					const findTranslation = (translations: Translation[]) => {
						const translation = translations.find(translation => {
							return translation.language === market
						})

						return translation?.value
					}

					const nameTranslation = findTranslation(product.product_translations) ?? name

					const collectionTranslation =
						findTranslation(product.collection_translations) ?? collection

					return {
						id,
						name: nameTranslation,
						collection: collectionTranslation,
						routine,
						link,
						image_url: imageUrl,
					}
				})

				const skincare = products.filter((v: IRoutine) => v.routine === 'Skincare')
				const makeup = products.filter((v: IRoutine) => v.routine === 'Makeup')
				return { ...data.data, makeup, skincare }
			},
			onError: (err: any) => {
				showNotification({
					title: 'Error !!!',
					message: err.response.data.error,
				})
			},
			keepPreviousData: true,
			enabled: !!batchId || !!customerId,
		},
	)

	return { data, isLoading }
}
